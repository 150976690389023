import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { FaGithub, FaLinkedin, FaEnvelope, FaGoogleDrive } from "react-icons/fa"
import siteConfig from '../../data/siteConfig'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import About from '../components/about'
import Skills from '../components/skills'
import Timeline from '../components/timeline'
import Repositories from '../components/repositories'

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
`;

class Home extends React.Component {
  render () {
    const title = 'Hello world! I\'m Ralph!';
    return (
      <Layout location={this.props.location}>
        <SEO
          title={title}
          keywords={['Ralph Woiwode', 'rawoiwode', 'gatsbyjs', 'react', 'resume', 'Software Engineer', 'Software Developer']}
        />

        <Hero
          heroImg={siteConfig.siteCover}
          title={title}
        />

        <Wrapper className={this.props.className} >
          <Container className="page-content" fluid>
            <Row>
              <Col xs={4} className='avatar'>
                {/*<img*/}
                  {/*className='avatar__image'*/}
                  {/*src='/images/avatar.jpeg'*/}
                  {/*alt='user avatar'*/}
                {/*/>*/}
                <div className="social">
                  {siteConfig.social.github && <a className="social-link github" href={siteConfig.social.github} target="_blank">
                    <FaGithub className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.linkedin && <a className="social-link linkedin" href={siteConfig.social.linkedin} target="_blank">
                    <FaLinkedin className="social-icon" size="32" />
                  </a>}
                  {/*{siteConfig.social.twitter && <a className="social-link twitter" href={siteConfig.social.twitter}>*/}
                    {/*<FaTwitter className="social-icon" size="32" />*/}
                  {/*</a>}*/}
                  {siteConfig.social.email && <a className="social-link email" href={`mailto:${siteConfig.social.email}`} target="_blank">
                    <FaEnvelope className="social-icon" size="32" />
                  </a>}
	                {siteConfig.social.resume && <a className="social-link resume" href={siteConfig.social.resume} target="_blank">
                    <FaGoogleDrive className="social-icon" size="32" />
                  </a>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <About title='About' text={siteConfig.authorDescription}/>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
	              <h1>Skills</h1>
	              <Row>
		              <Col sm={4}>
			              <Skills title='Languages' skills={siteConfig.skillsLanguages} />
		              </Col>
		              <Col sm={4}>
			              <Skills title='Web-based' skills={siteConfig.skillsWebbased} />
		              </Col>
	              </Row>
	              <Separator />
	              <Row>
		              <Col sm={2}>
			              <Skills title='Software' skills={siteConfig.skillsSoftware} />
		              </Col>
		              <Col sm={2}>
			              <Skills title='Database' skills={siteConfig.skillsDB} />
		              </Col>
		              <Col sm={2}>
			              <Skills title='Frameworks' skills={siteConfig.skillsFrameworks} />
		              </Col>
		              <Col sm={2}>
			              <h3>Other</h3>
			              <p>Linux, macOS, Windows, GraphQL, TDD, BDD, Fuzzing, Concolic Execution</p>
		              </Col>
	              </Row>
              </Col>
            </Row>
            <Separator />
            <Timeline />
            <Separator />
            <Repositories />
          </Container>
        </Wrapper>
      </Layout>
    )
  }
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.resume:hover {
    color: #0F9D58;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077B5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }
`
